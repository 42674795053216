"use client";
import Link from "next/link";
import {
  CircleDollarSignIcon,
  Paintbrush2,
  ChevronRight,
  ChevronLeft,
  PackageSearch,
  Home,
} from "lucide-react";
import { usePathname, useSelectedLayoutSegments } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "@/app/i18n/client";
import { Tooltip } from "antd";
import { APP_ROUTE } from "@/src/lib/app-route";
import { AppConsts } from "@/src/lib/constants";

interface IProps {
  template?: string;
}

export default function Nav({ template }: IProps) {
  const { t } = useTranslation("en", "common");
  const segments = useSelectedLayoutSegments();

  const tabs = useMemo(() => {
    const menuItems =
      template == AppConsts.appProjectType.property
        ? []
        : [
            {
              name: t("nav.home"),
              href: "/",
              isActive: segments.length === 0,
              icon: <Home width={18} />,
              bgColor: "bg-orange-600",
            },
            {
              name: t("nav.tools"),
              href: APP_ROUTE.tool.tools,
              isActive: segments[0] === "tools",
              icon: <Paintbrush2 width={18} />,
              bgColor: "bg-red-600",
            },
            {
              name: t("nav.resources"),
              href: APP_ROUTE.resource.resources,
              isActive: segments[0] === "resources",
              icon: <CircleDollarSignIcon width={18} />,
              bgColor: "bg-black",
            },
            {
              name: t("nav.keywordAnalyze"),
              href: APP_ROUTE.tool.keywordAnalyze,
              isActive:
                segments[0] === "tools" && segments[1] === "keyword-analyze",
              icon: <PackageSearch width={18} />,
              bgColor: "bg-blue-600",
            },
          ];
    return menuItems;
  }, [segments]);

  const [showSidebar, setShowSidebar] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    // hide sidebar on path change
    setShowSidebar(false);
  }, [pathname]);

  return (
    <div
      className={`transform ${
        showSidebar ? "mb:w-full translate-x-0" : "-translate-x-full"
      } fixed z-10 flex h-full flex-col justify-between border-r bg-white p-4 transition-all sm:w-[75px]`}
    >
      {showSidebar && (
        <div className="grid gap-4">
          {tabs.map(({ name, href, bgColor, icon }) => (
            <Tooltip placement="right" title={name} key={name}>
              <Link
                href={href}
                className={`rounded-full p-1 transition-all duration-150 ease-in-out hover:-translate-y-1 hover:shadow-lg`}
              >
                <span
                  className={`flex h-10 w-10 items-center justify-center rounded-full ${bgColor} text-white   `}
                >
                  {icon}
                </span>
              </Link>
            </Tooltip>
          ))}
        </div>
      )}
      <button
        className="btn-left-nav"
        onClick={() => setShowSidebar(!showSidebar)}
        aria-label="menu button"
      >
        {showSidebar ? <ChevronLeft /> : <ChevronRight />}
      </button>
    </div>
  );
}
